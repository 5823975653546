import { LayoutNotLogged as DefaultLayout } from "@royalcanin-be-partner-portal/ui-kit";
import React from "react";
import { useLocale, useSetLocale } from "../lib/locale";
import { footerLinks } from "./Layout";

export const LayoutNotLogged = (props: any) => {
  const locale = useLocale();
  const setLocale = useSetLocale();

  return (
    <DefaultLayout
      locale={locale}
      onLocaleChange={setLocale}
      links={footerLinks.map((footerLink) => ({
        ...footerLink,
        href: footerLink.link[locale],
      }))}
      {...props}
    />
  );
};
