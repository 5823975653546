import React, { useState } from "react";
import { withPage } from "../lib/withPage";
import { RouteComponentProps, Redirect } from "@reach/router";
import { LayoutNotLogged } from "../components/LayoutNotLogged";
import { parse } from "query-string";
import {
  useUserCheckPasswordTokenValidity,
  useUserUpdateRecoveredPassword,
  useMetadataAccountPasswordPolicy,
} from "@igloo-be-omnipartners/hooks";
import {
  Alert,
  PasswordManagerForm,
  Link,
  FormLoginContainer,
  Loader,
} from "@royalcanin-be-partner-portal/ui-kit";
import { ApolloError } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const PasswordManager = ({ location }: RouteComponentProps) => {
  const {
    recoveryToken,
    recoverytoken,
    token,
  }: { recoveryToken?: string; recoverytoken?: string; token?: string } = parse(
    location?.search || "",
  );
  const recoveryPasswordToken = recoveryToken || recoverytoken || token || "";
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { isValid, loading } = useUserCheckPasswordTokenValidity(
    recoveryPasswordToken,
  );
  const {
    error,
    userUpdateRecoveredPassword,
  } = useUserUpdateRecoveredPassword();
  const {
    passwordPolicy: passwordPolicyData,
    loading: passwordPolicyLoading,
  } = useMetadataAccountPasswordPolicy();
  const passwordPolicy = passwordPolicyData?.alt_password_format;

  if (loading || passwordPolicyLoading) {
    return <Loader />;
  }

  if (!recoveryPasswordToken) {
    return <Redirect to="/" noThrow />;
  }

  if (error instanceof ApolloError) {
    return <ErrorView error={error.message} />;
  }

  if (!isValid || (error && error.code === "OP/OPStatusError/2")) {
    return (
      <ErrorView
        error={
          <FormattedMessage
            id="recoverypassword.error.tokenValidity"
            defaultMessage="Votre token est invalide ou expiré. Veuillez recommencer la procédure."
          />
        }
      />
    );
  }

  return (
    <LayoutNotLogged>
      <PasswordManagerForm
        loading={loading}
        error={error?.message}
        disabled={!isValid || isSuccess}
        onSubmit={async (password) => {
          setIsSuccess(false);
          const {
            error: errorRecoveredPassword,
          } = await userUpdateRecoveredPassword({
            token: recoveryPasswordToken,
            password,
          });
          if (!errorRecoveredPassword) {
            setIsSuccess(true);
          }
        }}
        isSuccess={isSuccess}
        passwordPolicy={passwordPolicy || undefined}
      />
    </LayoutNotLogged>
  );
};

export default withPage(PasswordManager);

const ErrorView = ({ error }: { error: string | JSX.Element }) => (
  <LayoutNotLogged>
    <FormLoginContainer>
      <AlertContainer>
        <Alert type="error">{error}</Alert>
      </AlertContainer>
      <Link href="/">
        <FormattedMessage
          id="button.label.recoveryPassword.back"
          defaultMessage="Retour"
        />
      </Link>
    </FormLoginContainer>
  </LayoutNotLogged>
);

const AlertContainer = styled.div`
  margin-bottom: 30px;
`;
